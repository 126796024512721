import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  // Desktop View
  desktopCard: {
    fontFamily: "Roboto",
    marginBottom: "7%",
    backgroundColor: "#FFFFFF",
  },
  desktopCardContent: {
    margin: "1px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    padding: "0px 24px 0px 24px !important"
  },
  desktopLink: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: "5%",
  },
  noSolicitCard: {
    width: "257px",
    fontFamily: "Roboto",
    marginBottom: "7%",
  },
  noSolicitCardContent: {
    margin: "20px 9px 4px 8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    height: "180px",
  },
  titleBox: {
    // fontWeight: "500",
    padding: "24px",
    color: "white",
    backgroundColor: "#25576D",
    letterSpacing: "2px",
  },
  cardLinkContainer: {
    padding: "24px 0px",
  },

  // Mobile View
  mobileCard: {
    width: "327px",
    marginBottom: "24px",
    backgroundColor: "#FFFFFF",
    paddingBottom: "8px"
  },
  mobileTitleBox: {
    padding: "24px 24px 0px 24px"
  },
  mobileCardContent: {
    padding: "0px 24px 0px 24px !important",
    margin: "0px 0px 24px 0px",
  },
  mobileLink: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: "12px",
  },

  // Universal for Desktop and Mobile View
  linkBox: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default useStyles;
