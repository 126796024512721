import React, { useState, useEffect, useMemo } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  Typography,
  Box,
  TextField,
  Select,
  MenuItem,
  Link,
  Button,
} from "@material-ui/core";
import WarningIcon from "@mui/icons-material/Warning";
import styles from "../styles";
import { connect, useSelector } from "react-redux";
import { getPersonInfoWithName } from "../../../redux/demographics/demographicsActions";
import { selectPersonByIrnAndType } from "../../../redux/demographics/personsWithNameSelector";

function CourseTableRow(props) {
  const useStyles = styles;
  const classes = useStyles();
  const state = useSelector(state => state);

  const [value, setValue] = useState("Note text");
  const [placeholder, setPlaceholder] = useState("Type something...");

  const [studentFullName, setStudentFullName] = useState("");  
  const [studentPersonId, setStudentPersonId] = useState("");
  
  const [focused, setFocused] = useState(false);

  const studentPersonType = "student";

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const [gradeValue, setGradeValue] = useState("Yes");

  const handleGradeChange = (event) => {
    setGradeValue(event.target.value);
  };

  useEffect(() => {
    if (props.student && props.student.irn && !studentFullName) {
      props.getPersonInfoWithName(props.student.irn, studentPersonType)
    }
  }, []);

  useEffect(() => {
    if (props.student && props.student.irn) {
      const studentPerson = selectPersonByIrnAndType(
        state,
        props.student.irn,
        studentPersonType);
      if (studentPerson) {
        setStudentPersonId(studentPerson.id);
        setStudentFullName(`${studentPerson.firstName} ${studentPerson.lastName}`);
        return;
      }
    }
    else {
      setStudentPersonId(null);
      setStudentFullName('');
    }
  }, [props.student, state]);
   
  return (
    <>
      <TableRow
        key={props.student.irn}
        className={
          props.student.newAccomodation
            ? classes.newAccomContainer
            : classes.rowContainer
        }
      >
        <TableCell
          align="left"
          className={
            props.student.newAccomodation
              ? classes.newAccomStickyColumn
              : classes.stickyColumn
          }
        >
          <Typography className={classes.boldText}>
            {studentFullName}
          </Typography>
          {/*remove studentPersonId and line break from display later*/studentPersonId} <br /> 
          {props.student.irn}
        </TableCell>
        <TableCell align="left">{props.student.type}</TableCell>
        <TableCell align="left">
          <Button href="#" role="button" className={classes.linkText}>
            <Typography>{props.student.accomodations}</Typography>
          </Button>
        </TableCell>
        <TableCell>
          <TextField
            id="description notes" // Unique ID for accessibility
            name="course"
            value={value}
            onChange={handleChange}
            // placeholder={
            //   focused && value.length === 0 ? "Type something..." : ""
            // }
            placeholder={focused && value.length === 0 ? "" : ""}
            variant="outlined"
            color="secondary"
            inputProps={{ maxLength: 4000 }}
            disabled={props.student.newAccomodation}
            aria-disabled={props.student.newAccomodation}
            className={
              props.student.newAccomodation
                ? classes.textFieldGray
                : classes.textField
            }
            label={
              value.length > 0 && !focused
                ? ""
                : focused
                ? value.length === 0
                  ? "Optional field"
                  : "Optional field"
                : "Type something..."
            }
            InputLabelProps={{
              shrink: value.length > 0 || focused, // Shrinks the label if there is a value or the field is focused
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </TableCell>

        <TableCell className={classes.tableCell}>
          <Select
            value={gradeValue}
            onChange={handleGradeChange}
            displayEmpty
            variant="outlined"
            color="secondary"
            className={
              props.student.newAccomodation
                ? classes.selectGray
                : classes.select
            }
            disabled={props.student.newAccomodation}
          >
            <MenuItem
              value="Yes"
              aria-label="Yes"
              className={classes.menuItemFocused}
            >
              Yes
            </MenuItem>
            <MenuItem
              value="No"
              aria-label="No, negative"
              className={classes.menuItemFocused}
            >
              No
            </MenuItem>
          </Select>
        </TableCell>
        <TableCell align="center">
          {props.student.newAccomodation ? (
            <>
              <div>
                <WarningIcon
                  className={classes.warningIcon}
                  aria-label="warning icon"
                  aria-hidden="false"
                />
              </div>
              <Button
                href="#"
                className={classes.linkText}
                role="button"
                onClick={() => props.setOpen(true)}
              >
                <div>
                  <Typography>New student</Typography>
                  <Typography>accomodation</Typography>
                </div>
              </Button>
            </>
          ) : (
            <>
              <Button
                href="#"
                role="button"
                className={classes.linkText}
                aria-label="Review FNF link"
              >
                <Typography>Review FNF</Typography>
              </Button>
            </>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    personsWithName: state.personsWithName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPersonInfoWithName: (irn,personType) => dispatch(getPersonInfoWithName(irn,personType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseTableRow);
