import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import styles from "./InfoFooter.styles";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const InfoFooter = (props) => {
  const { students, credits, weeks, courseOfferingType, unitType, accommodations } = props;
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box className={matches ? classes.footerStyle : classes.footerMobileStyle}>
      <Grid container spacing={1} className={classes.mainContent}>
        <Grid item sm={2} className={classes.leftBox}>
          <Typography
            component="p"
            sx={{ fontWeight: "700", lineHeight: "28px", fontSize: "24px" }}
          >
            {students}
            <Typography
              sx={{ lineHeight: "21px", fontSize: "15px" }}
              component="p"
            >
              {`Students`}
            </Typography>
          </Typography>
        </Grid>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          className={matches ? classes.dividerColor : classes.hide}
        />
        <Grid item sm={3} className={classes.centerBox}>
          <Typography
            component="p"
            sx={{ fontWeight: "700", lineHeight: "28px", fontSize: "24px" }}
          >
            {accommodations}
            <Typography
              sx={{ lineHeight: "21px", fontSize: "15px" }}
              component="p"
            >
              {`Accommodations`}
            </Typography>
          </Typography>
        </Grid>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          className={matches ? classes.dividerColor : classes.hide}
        />
        <Grid item sm={3} className={matches ? classes.centerBox : classes.hide}>
          <Typography
            component="p"
            sx={{ fontWeight: "700", lineHeight: "28px", fontSize: "24px" }}
          >
            {credits}
            <Typography
              sx={{ lineHeight: "21px", fontSize: "15px" }}
              component="p"
            >
              {unitType}
              {/* {courseOfferingType === "DA" ? `Units` : `Credits`} */}
            </Typography>
          </Typography>
        </Grid>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          className={matches ? classes.dividerColor : classes.hide}
        />
        <Grid item sm={3}  className={matches ? classes.rightBox : classes.hide}>
          <Typography
            component="p"
            sx={{ fontWeight: "700", lineHeight: "28px", fontSize: "24px" }}
          >
            {weeks}
            <Typography
              sx={{ lineHeight: "21px", fontSize: "15px" }}
              component="p"
            >
              {`Weeks`}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InfoFooter;
