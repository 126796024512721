import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  // Desktop View
  desktopLink: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: "5%",
  },
  mobileLink: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: "12px",
  },
  mainContainer: {
    background: "#FFFFFF",
    borderRadius: "3px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0,0.15)",
    padding: "1px",
    marginBottom: "7%",
  },

  // MobileView
  mainContainerMobile: {
    width: "327px",
    background: "#FFFFFF",
    borderRadius: "3px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0,0.15)",
    padding: "1px",
    marginBottom: "24px",
  },

  // Universal for Desktop and Mobile View
  innerContainer: {
    margin: "25px",
  },
  infoContainer: {
    display: "flex",
    marginBottom: "22px",
  },
  linkContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
  },
  supportIcon: {
    width: "25px",
    height: "25px",
    margin: "0px 5px 0px 0px",
  },
  emailIconRoot: {
    marginRight: "5px",
  },
}));

export default useStyles;
